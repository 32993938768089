//navigation

.menuMain {
  margin-bottom: 0rem;
}

//menu bar
@media (min-width: $nav-breakpoint + 1px) {
  .menuMain .topLevel {
    display: inline-flex;
    float: right;
    border-top: 1px solid #e8e8e8;
    position: relative;
    z-index: 2;
  }
}


//intro colour
.homeIntro h3 {
  color: $brand-primary;
}
//carousel header
.carouselSlideHeading {
  font-weight: 900;
}
span.readMore.carouselSlideReadMore {
  border: solid $blue 1px;
}
//homeFeatures

.homeFeatures .homeFeature {
  margin: 2px;
}

.homeFeatures
  .homeFeature
  [class*="DetailsWrapper"]:not(.publishDetailsWrapper),
.homeFeatures .homeFeature [class^="listed"][class$="Text"] {
  width: calc(100% - 9.25rem);

  transform: translate(10px, -70px);
}

.homeFeatures .homeFeature h2 {
  margin-bottom: 0;
  text-transform: uppercase;
}

.feedItem .feedTitle {
  text-transform: uppercase;
}

//quick giving
.homefeaturecategory-homeboxquickgiving
  .quickGivingPanel
  .formQuestion.donationAmount
  .donationAmountFigure {
  border: solid white 2px;
}

//impact

.homefeaturecategory-homeboximpactstats td {
  display: block;
  padding: 0;
  position: relative;
  // width: 200px;
  // height: 200px;
  &:first-child {
    height: 150px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      content: "";
      position: absolute;
      width: 210px;
      height: 200px;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
      border: 2px solid $blue;
      width: 150px;
      height: 150px;
      border-radius: 200px;
      background-color: white;
    }
    h2 {
      color: $blue;
      position: relative;
      z-index: 10;
    }
  }

  &:nth-child(2) {
    padding-top: 1rem;
  }
}

// JK - This is being applied to all 2nd child table cells across the whole site?.. removing
// td:nth-child(2) {
//   padding-top: 1rem;
// }

// .homefeaturecategory-homeboximpactstats td:last-child {
//   margin-top: 70px;
// }
//footer

ul.footerLinks {
  text-align: center;

  li {
    display: inline-block;
    padding: 0 5px;
  }
}

.footerBox3 {
  margin-top: 0;
  text-align: center;
  font-size: 14px;
  p {
    padding: 10px 0 0;
  }
}

.pageFooter ul.socialIcons {
  margin: 20px auto 20px;
  display: table;
  li {
    display: inline-block;
  }
}

//underlines
.carouselSlideHeading {
  @include heading-underline(
    $colour: $yellow,
    $width: 50px,
    $height: 2px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 0rem
  );
}

//intro
.homeIntro h3 {
  @include heading-underline(
    $colour: $yellow,
    $width: 50px,
    $height: 2px,
    $justify: center,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}

.homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
  @include heading-underline(
    $colour: $yellow,
    $width: 50px,
    $height: 2px,
    $justify: center,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}
.homeFeed .feedsTitle {
  margin-bottom: 1.5rem;
  @include heading-underline(
    $colour: $yellow,
    $width: 50px,
    $height: 2px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}

.footerBox1 {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../assets/impact_bg.svg);
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.02;
    pointer-events: none;
  }
  h1 {
    @include heading-underline(
      $colour: $yellow,
      $width: 50px,
      $height: 2px,
      $justify: center,
      $margin-top: 1rem,
      $margin-bottom: 0rem
    );
  }
}

//events feeds  titles

.feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper),
.feedItem [class^="listed"][class$="Text"] {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: calc(100% - 7.25rem);
  -webkit-transform: translate(10px, -70px);
  -ms-transform: translate(10px, -70px);
  transform: translate(10px, -70px);
}

//carosel image overlay

.carouselSlideTitle {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../assets/carousel_bg.svg);
    z-index: 0;
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: left;
    opacity: 1;
  }
}
//footerlogo
.footerlogos {
  display: table;
  margin: 0 auto;

  img {
    padding: 0 20px 20px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}

//tagline
.pageHeader .headerContent .phone {
  text-align: right;
  color: $brand-primary;
}

.pageHeader .headerContent .phone:before {
  content: "\f095";
  font-family: FontAwesome;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

// Weird thing happening on inner pages, content is all squished, god know why but this fixes it!!
.pageWrapper {
  overflow: unset;
}

// Facebook feeds fix
.homeFeed:nth-child(1).homeFeedBox1 {
  width: 100%;
  flex: unset;
}
.pageHeader .headerContent .mainCallToAction {
  flex-grow: 1;

  .phone {
    margin: 0 10px;
  }
}
.pageHeader .headerContent .header-search {
  order: 4;
}

.pageHeader .headerContent .mainCallToAction {
  order: 3;
}